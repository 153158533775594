<template>
    <div>
        <el-form v-model="studentInfo" label-width="150px" size="mini">
          <el-form-item label="学员姓名">
            <el-row>
              <el-col :span="20">
                {{ studentInfo.studentName ? studentInfo.studentName : '-'}}
              </el-col>
              <el-col :span="4">
                <el-button type="success" @click="handleEdit">编辑</el-button>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item label="当前服务号">
                <span v-for="item in serviceList" :key="item.servicesId"
                      v-if="studentInfo.servicesId === item.servicesId">{{ item.name }}</span>
          </el-form-item>
          <el-form-item label="首次下单手机号">
            {{ studentInfo.mobile }}
            <el-button type="text" v-if="!studentInfo.showMobile" @click="handleView(studentInfo)"><i
                class="el-icon-view"></i></el-button>
          </el-form-item>
            <el-form-item label="学员类型">
                <span v-for="item in studentTypeList" :key="item.id"
                      v-if="studentInfo.studentType === item.dictValue">{{ item.dictLabel }}</span>
            </el-form-item>
          <el-form-item label="证件号码">
            {{ studentInfo.idCard ? studentInfo.idCard : '-' }}
          </el-form-item>
            <!--            <el-form-item label="证件类型">-->
            <!--                <el-row>-->
            <!--                    <el-col :span="18">-->
            <!--                        {{ studentInfo.idCardType ? studentInfo.idCardType : '&#45;&#45;' }}-->
            <!--                    </el-col>-->
            <!--                    <el-col :span="6">-->
            <!--                        <el-button type="primary" size="medium" v-if="edit" @click="handleEdit">编辑</el-button>-->
            <!--                    </el-col>-->
            <!--                </el-row>-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label="报考省份">-->
            <!--                {{ studentInfo.studentType }}-->
            <!--            </el-form-item>-->
            <!--            <el-form-item label="是否学习相关专业">-->
            <!--                {{ studentInfo.studentType }}-->
            <!--            </el-form-item>-->
            <el-form-item label="最高学历">
                {{ studentInfo.education }}
            </el-form-item>
            <el-form-item label="工作年限">
                {{ studentInfo.working }}
            </el-form-item>
            <el-form-item label="收货地址">
                {{ studentInfo.address }}
            </el-form-item>
            <el-form-item label="首次沟通时间">
                {{ studentInfo.firstTime }}
            </el-form-item>
            <el-form-item label="是否从事相关工作">
                {{ studentInfo.worked ? '是' : '否' }}
            </el-form-item>
          <el-form-item label="是否加企微">
            {{ studentInfo.weworked ? '是' : '否' }}
          </el-form-item>
            <el-form-item label="是否加钉">
                {{ studentInfo.dingding ? '是' : '否' }}
            </el-form-item>
            <el-form-item label="是否进入架构">
                {{ studentInfo.framework ? '是' : '否' }}
            </el-form-item>
            <el-form-item label="是否进群">
                {{ studentInfo.crowd ? '是' : '否' }}
            </el-form-item>
            <el-form-item label="是否建档">
                {{ studentInfo.record ? '是' : '否' }}
            </el-form-item>
            <el-form-item label="是否发送协议">
                {{ studentInfo.sendProtocol ? '是' : '否' }}
            </el-form-item>
            <el-form-item label="是否签署协议">
                {{ studentInfo.signProtocol ? '是' : '否' }}
            </el-form-item>
            <el-form-item label="是否报考">
                {{ studentInfo.enroll ? '是' : '否' }}
            </el-form-item>
          <el-form-item label="学员备注">
            {{ studentInfo.remarks }}
          </el-form-item>
          <!--    订单信息      -->
          <el-divider></el-divider>
          <el-row style="padding-left: 20px;color: #409EFF;font-size: 14px;">订单信息</el-row>
          <el-tabs v-model="nowTab" tab-position="left" style="min-height: 100px">
            <el-tab-pane v-for="item in orderList" :label="item.goodsName" :name="item.orderId">
              <el-form-item label="商品名称" label-width="100px">
                <template #default>
                  <el-row>
                    <el-col :span="18">
                      {{ item.goodsName }}
                    </el-col>
                    <el-col :span="6">
                      <el-button type="info" v-permission="'waiter:order:delete'" @click="delOrder(item.orderId)">删除订单</el-button>
                    </el-col>
                  </el-row>
                </template>
              </el-form-item>
              <el-form-item label="成单类型" label-width="100px">
                        <span v-for="o in pondList" :key="o.id"
                              v-if="item.orderType === o.id">{{ o.name }}</span>
              </el-form-item>
              <el-form-item label="下单渠道" label-width="100px">
                        <span v-for="o in orderChannelList" :key="o.id"
                              v-if="item.orderChannel === o.dictValue">{{ o.dictLabel }}</span>
              </el-form-item>
              <el-form-item label="成单销售" label-width="100px">
                {{ item.orderSalesName }}
              </el-form-item>
              <el-form-item label="下单手机号" label-width="100px">
                {{ item.mobile }}
              </el-form-item>
              <el-form-item label="购买时间" label-width="100px">
                {{ item.buyTime }}
              </el-form-item>
              <el-form-item label="订单号" label-width="100px">
                {{ item.orderNo }}
              </el-form-item>
              <el-form-item label="下单金额" label-width="100px">
                {{ item.orderPrice ? item.orderPrice / 100 : '' }}
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </el-form>

        <el-dialog title="修改数据" :visible.sync="dataFromSync" width="60%" top="20px" append-to-body>
            <el-form :model="studentInfo" :rules="dataFromRules" ref="dataFrom" style="width: 500px" label-width="150px"
                     size="medium">
                <el-form-item label="手机号" prop="mobile">
                    {{ studentInfo.mobile }}
                </el-form-item>
                <el-form-item label="学员姓名" prop="studentName">
                    <el-input v-model="studentInfo.studentName"></el-input>
                </el-form-item>
                <el-form-item label="学员备注" prop="remarks">
                    <el-input v-model="studentInfo.remarks" type="textarea" :rows="4"></el-input>
                </el-form-item>
              <el-form-item label="学员类型">
                <el-select v-model="studentInfo.studentType">
                  <el-option v-for="item in studentTypeList" :key="item.id" :value="item.dictValue" :label="item.dictLabel"></el-option>
                </el-select>
              </el-form-item>

                <!--                <el-form-item label="证件类型" prop="idCardType">-->
                <!--                    <el-input v-model="studentInfo.idCardType"></el-input>-->
                <!--                </el-form-item>-->
                <el-form-item label="证件号码" prop="idCard">
                    <el-input v-model="studentInfo.idCard"></el-input>
                </el-form-item>
                <el-form-item label="最高学历" prop="education">
                    <el-input v-model="studentInfo.education"></el-input>
                </el-form-item>
                <el-form-item label="工作年限" prop="working">
                    <el-input v-model="studentInfo.working"></el-input>
                </el-form-item>
                <el-form-item label="收货地址" prop="address">
                    <el-input v-model="studentInfo.address" type="textarea" :rows="4"></el-input>
                </el-form-item>
                <el-form-item label="首次沟通时间" prop="firstTime">
                    <el-date-picker
                        v-model="studentInfo.firstTime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </el-form-item>
              <el-form-item label="是否加企微" prop="weworked">
                <el-switch
                    v-model="studentInfo.weworked"
                    active-text="是"
                    inactive-text="否">
                </el-switch>
              </el-form-item>
                <el-form-item label="是否从事相关工作" prop="worked">
                    <el-switch
                            v-model="studentInfo.worked"
                            active-text="是"
                            inactive-text="否">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否加钉" prop="dingding">
                    <el-switch
                            v-model="studentInfo.dingding"
                            active-text="是"
                            inactive-text="否">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否进入架构" prop="framework">
                    <el-switch
                            v-model="studentInfo.framework"
                            active-text="是"
                            inactive-text="否">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否进群" prop="crowd">
                    <el-switch
                            v-model="studentInfo.crowd"
                            active-text="是"
                            inactive-text="否">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否建档" prop="record">
                    <el-switch
                            v-model="studentInfo.record"
                            active-text="是"
                            inactive-text="否">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否发送协议" prop="sendProtocol">
                    <el-switch
                            v-model="studentInfo.sendProtocol"
                            active-text="是"
                            inactive-text="否">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否签署协议" prop="signProtocol">
                    <el-switch
                            v-model="studentInfo.signProtocol"
                            active-text="是"
                            inactive-text="否">
                    </el-switch>
                </el-form-item>
                <el-form-item label="是否报考" prop="enroll">
                    <el-switch
                            v-model="studentInfo.enroll"
                            active-text="是"
                            inactive-text="否">
                    </el-switch>
                </el-form-item>


            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="medium" @click="submitForm('dataFrom')">确 定</el-button>
                <el-button size="medium" @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

      <el-dialog title="修改详情" :visible.sync="infoFromSync" width="60%" top="20px" append-to-body>
        <el-form :model="studentInfo" :rules="infoFromRules" ref="infoFrom" style="width: 500px" label-width="150px"
                 size="medium">
          <el-form-item label="学员类型" prop="studentType">
            <el-select v-model="studentInfo.studentType">
              <el-option v-for="item in studentTypeList "  :key="item.id" :label="item.dictLabel" :value="item.dictValue"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学员姓名" prop="studentName">
            <el-input v-model="studentInfo.studentName"></el-input>
          </el-form-item>
          <el-form-item label="学员备注" prop="remarks">
            <el-input v-model="studentInfo.remarks" type="textarea" :rows="4"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" size="medium" @click="submitForm('infoFrom')">确 定</el-button>
          <el-button size="medium" @click="cancel">取 消</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
import * as studentApi from "@/api/waiter/waiterStudent"
import * as serviceApi from "@/api/waiter/waiterServices"
import * as accountApi from "@/api/system/account"
import * as pondApi from "@/api/clue/cluePond"
import * as dictionaryApi from "@/api/system/dictionary"
import {getOrderByStudentId} from "@/api/waiter/waiterStudent";

export default {
    name: "WaiterStudentDetailRecord",
    props: {
        studentInfo: {
            type: Object,
            default: () => {
            }
        },
        edit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
          infoFromSync:false,
          infoFromRules: {},
            studentTypeList: [],
            orderChannelList: [],
            pondList: [],
            accountList: [],
            serviceList: [],
            dataFromSync: false,
            dataFromRules: {},
            nowTab: '',
            orderList: [],
        }
    },
    mounted() {
        this.findService()
        this.findAccount()
        this.findPond()
        this.findStudentType()
        this.findOrderChannel()
        this.findOrder()
    },
    methods: {
      /**
       * 修改详情
       */
      handleInfo(row){
        this.infoFromSync = true;
      },
      /**
       * 删除订单
       * @param orderId
       */
      delOrder(orderId){
        studentApi.deleteOrder(orderId).then(res=>{
          if(res.success){
            this.$message.success(res.msg)
            this.findOrder()
          }else{
            this.$message.error(res.msg)
          }
        })
      },
        findOrder() {
            studentApi.getOrderByStudentId(this.studentInfo.studentId).then(res => {
                this.orderList = res.data
                if (this.orderList.length > 0) {
                    this.nowTab = this.orderList[0].orderId
                }
            })
        },
        handleEdit() {
            this.dataFromSync = true
        },
        /**
         * 取消
         */
        cancel() {
          this.infoFromSync = false
            this.dataFromSync = false
        },
        /**
         * 保存
         */
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    studentApi.saveData(this.studentInfo).then(res => {
                        if (res.success) {
                            this.$message({
                                type: "success",
                                message: '保存成功！'
                            });
                            this.dataFromSync = false
                            this.infoFromSync = false
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg
                            });
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        findStudentType() {
            dictionaryApi.findChildrenByCode('studentType').then(res => {
                if (res.success) {
                    this.studentTypeList = res.data
                }
            })
        },
        findOrderChannel() {
            dictionaryApi.findChildrenByCode('orderChannel').then(res => {
                if (res.success) {
                    this.orderChannelList = res.data
                }
            })
        },
        findPond() {
            pondApi.listCluePond().then(res => {
                if (res.success) {
                    this.pondList = res.data
                }
              this.pondList.unshift({id:1,name:'基础池'})
            })
        },
        findAccount() {
            accountApi.list().then(res => {
                if (res.success) {
                    this.accountList = res.data
                }
            })
        },
        findService() {
            serviceApi.findList().then(res => {
                if (res.success) {
                    this.serviceList = res.data
                }
            })
        },
        handleView(row) {
            studentApi.viewMobile(row.studentId).then(res => {
                if (res.success) {
                    row.mobile = res.data
                    row.showMobile = true
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
    }
}
</script>

<style scoped>
/deep/ .el-tabs--left .el-tabs__item.is-left {
    width: 147px;
}

</style>